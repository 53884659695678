<template>
  <div></div>
</template>

<script>
import axios from 'axios'
import LiffChecker from "@/utils/LiffChecker";

export default {
  mounted() {
    this.fetchFile()
  },
  methods: {
    async fetchFile() {
      let { org_code: orgCode, uploadFile: fileId } = this.$route.params
      let url = process.env.VUE_APP_API_BASE_URL + "/" + orgCode + "/liff/upload-file/" + fileId + "/download";
      let fileUrl = ""
      axios.get(url)
        .then(async response => {
          const { data } = response
          if (data.is_friend) {
            const checker = new LiffChecker(orgCode);
            await checker.initLiff();
          }
          fileUrl = data.url
        })
        .catch(error => {
          console.log(error)
          this.$swal.fire({
            title: "Error",
            text: "檔案已失效",
            icon: "error",
            confirmButtonText: "返回"
          }).then(() => {
            this.$router.go(-1)
          })
        }).finally(() => {
          window.location.href = fileUrl
        })
    }
  }
}
</script>
